/**
 * @desc oss上传文件
 * @author Author Name
 * @date 2015-10-10
 */
import config from "./config";
const OSS = require('ali-oss')

const client = new OSS({
  // yourregion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
  region: 'oss-cn-beijing',
  // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
  accessKeyId: 'LTAI5tCbkttRzzvH6RRGS6Hm',
  accessKeySecret: 'u1uO0v8UdXYhVpiID27wP3fLO4Hiqg',
  // 填写Bucket名称。
  bucket: 'sike-face',
});


/**
 * 
 * @param {object}   file 文件对象
 * @desc 上传文件
 */
function uploadFile(dir,file) {

  return new Promise((resolve, reject) => {

    //获取文件类型
    let fileType = file.type.split('/')[1];
    // 制作文件名
    let fileName = 'H5/' + dir + '/' +  new Date().getTime() + Math.floor(Math.random() * 150) + '.' + fileType;
    // 上传
    client.multipartUpload(fileName, file).then(result => {
      console.log(result, 'oss返回')

      //此处为返回文件名称:域名 + 文件名
      let onlinePath = config.aliyunConfig.uploadImageUrl + result.name;
      resolve(onlinePath)
    }).catch(err => {
      reject(err)
    })


  })
}

/**
 * 
 * @param {string}   path 文件链接
 * @desc 删除某个文件
 */
function deleteFile(path = undefined, options = {}) {

  if (!path) return;
  // 匹配路径
  // https://sike-linbao.oss-cn-beijing.aliyuncs.com/1629361368726.jpeg'
  // 匹配路径1629361368726.jpeg
  path = path.split('com/')[1]

  return new Promise(async (resolve, reject) => {
    try {
      // 先判断图片是否存在于oss
      let isExited = await isExistObject(path,options);
      if(isExited) {
        // 再删除图片
        let result = await client.delete(path);
        resolve(result)
      }else {
        reject('文件不存在');
      }
    } catch (err) {
      reject(err)
    }
  })
}

/**
 * 
 * @param {Array}   arr 文件数组
 * @desc 删除文件数组
 */
function deleteMulti (arr) {

   if(arr.length == 0) return;

   let arrList = []
   for(let i=0;i<arr.length; i++) {
      let path = arr[i];
      path = path.split('com/')[1]
      arrList.push(path);
   }

   return new Promise(async(resolve,reject) => {
    try {
      // 填写需要删除的多个Object完整路径并设置返回模式为详细模式。Object完整路径中不能包含Bucket名称。
      //let result = await client.deleteMulti(['exampleobject-1', 'exampleobject-2', 'testfolder/sampleobject.txt']);
      //console.log(result);
      // 填写需要删除的多个Object完整路径并设置返回模式为简单模式。Object完整路径中不能包含Bucket名称。
      let result = await client.deleteMulti(arrList, {quiet: true});     
      console.log(result);
      resolve(result)
    } catch (e) {
      console.log(e);
      reject(e)
    }
   })
}

/**
 * 
 * @param {string}   name 文件名称
 * @param {Object}   options 配置
 * @desc 判断某个文件是否存在
 */
function isExistObject(name, options = {}) {
  return new Promise((resolve,reject) => {
    client.head(name, options).then(() => {
      resolve(true)
    }).catch(() => {
      reject(false)
    })
  })
}


export {
  uploadFile,
  deleteMulti,
  deleteFile,
};