
/**
 * @description 阿里云相关配置
 * 
 * **/ 

var aliyunConfig = {
    uploadImageUrl: 'https://sike-face.oss-cn-beijing.aliyuncs.com/', // 默认存在根目录，可根据需求改
    AccessKeySecret: 'u1uO0v8UdXYhVpiID27wP3fLO4Hiqg',        // AccessKeySecret 去你的阿里云上控制台上找
    OSSAccessKeyId: 'LTAI5tCbkttRzzvH6RRGS6Hm',         // AccessKeyId 去你的阿里云上控制台上找
    timeout: 80000 //这个是上传文件时Policy的失效时间
 }



export default {

    baseUrl:'',//本地地址

    onlineUrl:'',//线上地址

    aliyunConfig

}